:root {
    --primary-color: #3f51b5;
}



.letter {
    font-family: 'Courier New', Courier, monospace;
    font-size: 1.4em;
    font-weight: 700;
    width: 1em;
    display: inline-block;
    text-align: center;
    margin: 0 0 0 0.3em;
    border-radius: 10px;
}


.needle {
    color: var(--primary-color);
    margin-bottom: 10px;


}

.haystack {
    /* padding-bottom: 10px; */
    margin-bottom: 7px;

}

/* TODO add media queries for font size */
pre {
    font-size: 1rem;
    padding-left: 0.3rem;
}

.code-highlight {
    background-color: var(--primary-color);
    color: white;
    transition: all 0.2s ease-in-out;
    margin-top: 0.7rem;
    margin-bottom: 0.7rem;
}

.code {
    margin-top: 0.7rem;
    margin-bottom: 0.7rem;
}

.highlight {
    background-color: var(--primary-color);
    border-radius: 10px;
    color: white;
    transition: background-color 0.1s linear;

}

.highlight-outline {
    background-color: white;
    border-radius: 10px;
    border-width: 3px;
    border-color: var(--primary-color);
    border-style: solid;
    color: var(--primary-color);
}

.correct {
    background-color: #4CAF50;
    color: white;
    transition: background-color 0.1s linear;

}

.incorrect {
    background-color: #f44336;
    color: white;
    transition: background-color 0.1s linear;

}

.variable-value {
    font-family: 'Courier New', Courier, monospace;
    font-weight: 600;
    font-size: 1.2rem;
}

.found {
    box-shadow: 0px 3px 3px -2px rgb(0 255 29 / 43%), 0px 3px 4px 0px rgb(38 144 42 / 34%), 0px 1px 8px 0px rgb(47 165 45) !important;
}

.not-found {
    box-shadow: 0px 3px 3px -2px #f443365c, 0px 3px 4px 0px #af2d2373, 0px 1px 8px 0px #f4433661 !important;
}